.App {
  text-align: center;
}
@font-face {
  font-family: pixel;
  src: url(./FreePixel.ttf);
}
h1{
  text-align: center;
  margin-top: 10vh;
}
.centerbutton{
  display: flex;
  margin-top: 30px;
  justify-content: center;
}


body{
  /*background: url(backgroundhalo.png) center no-repeat;
  background-size: cover;*/
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #1f1f1f;
}
.flexmain{
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: top;
  
}
.flexmain img{
  position: absolute;
  top: 0;
}
.left{
  left: 0;
}
.right{
  right: 0;
}
.cage{
  position: relative;
}
.cage button{
  position: absolute;
  bottom: 120px;
}
.cage img{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.MuiPaper-root{
  background: transparent!important;
  padding: 0px!important;
  box-shadow: none!important;
}
.button{
  height: 60px;
}
.cage{
  width: 437px;
  height: 702px;
  animation: comefromtop 3s ease-in-out;
}
@keyframes comefromtop{
  0%{
    transform: translateY(-100%);
  }
  100%{
    transform: translateY(0);
  }
}
.MuiContainer-root{
  margin-top: 0px!important;
}
.midalign{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.smaller{
  font-size: 20px!important;
}
.MuiButton-label{
  display: flex!important;
  flex-direction: column!important;
}
.midalign h1{
  margin-bottom: 100px;
  font-family: pixel;
  font-size: 60px;
  font-weight: bold;
}
.MuiTypography-root{
  font-family: pixel!important;
  font-size: 20px;
  font-weight: bold;
}
@media(max-width: 800px){
  .cage{
    width: 90%;
    height: 626px;
    margin-inline: auto;
  }
  .cage img{
    width: 100%;
    height: 626px;
  }
  .MuiContainer-root{
    padding: 0px!important;
  }
  .midalign{
    width: 90%;
    margin-inline: auto;
  }
  .flexmain .left, .flexmain .right{
    width: 45vw;
  }
}